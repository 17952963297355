import React, { FC } from 'react';

import styled from 'styled-components';

import SmartSessionStorage from '../../../helpers/storage/SmartSessionStorage';
import { safeGet } from '../../../helpers/utils';

import { DFPProps, SizeMapping, StylesForAdSlotType, UnitProps } from './';

type minHeightMap = {
  viewWidth: number;
  minHeightObj: { width: number; height: number };
};
type minHeightMapArray = minHeightMap[];

const viewportSorter = (a: minHeightMap, b: minHeightMap) => {
  const aViewWidth = a.viewWidth;
  const bViewWidth = b.viewWidth;
  if (aViewWidth > bViewWidth) {
    return 1;
  }

  if (aViewWidth < bViewWidth) {
    return -1;
  }
  return 0;
};

const StyledAdSlot = styled.div<StylesForAdSlotType>`
  .adBox {
    background: #ffffff;
    z-index: 100;
    position: relative;
    text-align: ${({ textAlign = 'center' }) => textAlign};
    min-height: ${({ minHeight = '0' }) => minHeight};
    display: none;

    &:not(:empty) {
      display: block;

      > div:first-child {
        display: inline-block;
      }
    }
  }

  @media print {
    display: none;
  }
`;

const AdPosition = styled.div<{
  padding?: string;
  margin?: string;
  justifyContent?: string;
}>`
  display: flex;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0 auto' }) => margin};
`;

const AdBackground = styled.div<{
  minHeightMapArray: minHeightMapArray;
  disableBackground: boolean;
}>`
  ${({ minHeightMapArray, disableBackground }) => {
    if (!minHeightMapArray || disableBackground) return '';
    const sortedMap = minHeightMapArray.sort(viewportSorter);

    return sortedMap.reduce((cssOutput, minHeightObj) => {
      return `
      ${cssOutput}
      @media only screen and (min-width: ${minHeightObj.viewWidth}px) {
        min-height: ${minHeightObj.minHeightObj.height}px;
        width: ${minHeightObj.minHeightObj.width}px;
        background: #ffffff;
        display: ${minHeightObj.minHeightObj.height === 0 ? ' none' : 'flex'};
        >div{
          flex: 1;
        }
      }
    `;
    }, '');
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BackgroundSpacing = styled.span`
  position: absolute;
  left: calc(50% - 55px);
  top: calc(50% - 9px);
  z-index: 0;
  ${({ disableBackground }: { disableBackground: boolean }) =>
    disableBackground ? 'display: none;' : ''}
`;

const getDfpTiling = () => {
  if (typeof window !== 'undefined') {
    if (SmartSessionStorage.get('tilingValue')) {
      return SmartSessionStorage.get('tilingValue');
    }

    const value = Math.floor(Math.random() * 10).toString();
    SmartSessionStorage.set('tilingValue', value);
    return value;
  }
};

export const getTargetingArguments = (
  targetingArguments: {
    [key: string]: string | [string];
  } = {},
  debugMode = false,
) => {
  const updatedArgs = { ...targetingArguments, tiling: getDfpTiling() };
  if (debugMode) {
    return { ...updatedArgs, site: 'debugmode' };
  }
  return { ...updatedArgs };
};

// This ensures no unit is displayed until the first declaration of one
const generateSizeMap = (sizeMapping: SizeMapping[]) => [
  ...sizeMapping,
  {
    viewport: [0, 0],
    sizes: [],
  },
];

export const DFPUnit: FC<UnitProps> = ({
  styles = {},
  sizeMapping,
  dfpDisabled,
  AdSlot,
  disableBackground = false,
  ...rest
}) => {
  if (dfpDisabled) {
    return null;
  }

  const currentSizeMap = generateSizeMap(sizeMapping);

  const minHeightMapArray = currentSizeMap.reduce(
    (acc: minHeightMapArray, sizeMap) => {
      const [viewWidth] = sizeMap.viewport;
      const currentSizes = safeGet(sizeMap, ['sizes']);
      let minHeightObj = { width: 0, height: 0 };

      if (currentSizes) {
        minHeightObj = currentSizes.reduce(
          (
            targetHeightObj: { width: number; height: number },
            adSize: [number, number],
          ) => {
            const [width, height] = adSize;
            return height > targetHeightObj.height
              ? { width, height }
              : targetHeightObj;
          },
          minHeightObj,
        );
      }

      return [
        ...acc,
        {
          viewWidth,
          minHeightObj: {
            ...minHeightObj,
          },
        },
      ];
    },
    [],
  );

  return (
    <StyledAdSlot {...styles} data-testid={`dfp-slot-${rest.slotId}`}>
      <AdPosition
        margin={styles.margin}
        padding={styles.padding}
        justifyContent={styles.justifyContent}
      >
        <AdBackground
          minHeightMapArray={minHeightMapArray}
          disableBackground={disableBackground}
        >
          <AdSlot sizeMapping={currentSizeMap} {...rest} />
          <BackgroundSpacing
            disableBackground={disableBackground}
          ></BackgroundSpacing>
        </AdBackground>
      </AdPosition>
    </StyledAdSlot>
  );
};

export const setDFPProps = (
  { targetingArguments, dfpNetworkId, collapseEmptyDivs = true }: DFPProps,
  debugMode = false,
) => ({
  dfpNetworkId,
  collapseEmptyDivs,
  targetingArguments: getTargetingArguments(targetingArguments, debugMode),
});

export const DFPRefresh = (googletag: string, DFPManager: any) => {
  if (googletag) {
    DFPManager.refresh();
  }
};

export const DFPLoad = (DFPManager: any) => {
  DFPManager.load();
};
