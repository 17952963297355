import { SearchRequest } from '../../../../services/Filters/FilterTypes';
import {
  locationQueryFromAreaTags,
  rangeFiltersToQueryParams,
  simpleFiltersToQueryParams,
} from '../../../../services/Search/searchRequestToQueryParams';
import { AreaTag, SECTIONS } from '../../../../types';

import { LastSearchType, formattedSection } from './LastSearch.types';

export const useLastSearch = ({
  searchQuery,
  locationList,
  getTitleSearch,
}: {
  searchQuery: SearchRequest;
  locationList?: AreaTag[];
  getTitleSearch?: () => string;
}) => {
  const getNumberOfFilters = (): string => {
    let totalFilterCount = 0;

    const { filters, andFilters, ranges, terms } = searchQuery;

    if (filters && filters.length) {
      for (const filter of filters) {
        if (filter.name === 'roomType') {
          continue;
        }
        totalFilterCount++;
      }
    }

    if (andFilters) {
      totalFilterCount += andFilters.length;
    }

    if (ranges) {
      for (const range of ranges) {
        if (
          range.name === 'numBeds' ||
          range.name === 'salePrice' ||
          range.name === 'rentalPrice'
        ) {
          continue;
        }
        totalFilterCount++;
      }
    }

    if (terms) {
      totalFilterCount++;
    }

    if (totalFilterCount <= 0) {
      return '';
    }

    return totalFilterCount > 1
      ? '(+' + totalFilterCount + ' filters)'
      : '(+' + totalFilterCount + ' filter)';
  };

  const generateParams = (lastSearch: LastSearchType) => {
    //adState
    const simpleFilters =
      lastSearch?.searchQueryUpdated.filters &&
      simpleFiltersToQueryParams(lastSearch?.searchQueryUpdated.filters);

    //facilities
    const andFilters =
      lastSearch &&
      simpleFiltersToQueryParams(lastSearch.searchQueryUpdated?.andFilters);

    //filters with from/to value: numBaths, numBeds, rentalPrice, salePrice...
    const ranges =
      lastSearch &&
      rangeFiltersToQueryParams(lastSearch?.searchQueryUpdated?.ranges);

    const sortFromRequest = lastSearch && lastSearch.searchQueryUpdated?.sort;
    const params = {
      ...(lastSearch?.area ?? { locationPath: 'ireland' }),
      terms: lastSearch.searchQueryUpdated?.terms,
      ...simpleFilters,
      ...andFilters,
      ...ranges,
      sort: sortFromRequest,
    };
    const { sort, ...paramsWithoutSort } = params;
    const linkParams =
      !sortFromRequest || sortFromRequest === 'bestMatch'
        ? paramsWithoutSort
        : params;

    return linkParams;
  };

  const createLastSearch = () => {
    const {
      geoFilter: { storedShapeIds },
    } = searchQuery;

    const titleSearch = getTitleSearch && getTitleSearch();

    const searchQueryWithSortFilter = searchQuery.sort
      ? searchQuery
      : { ...searchQuery, sort: 'bestMatch' };

    const searchQueryUpdated = storedShapeIds
      ? searchQueryWithSortFilter
      : { ...searchQueryWithSortFilter, geoFilter: { storedShapeIds: [] } };

    const numberOfFilters = getNumberOfFilters();

    const area =
      locationList && locationQueryFromAreaTags(locationList, storedShapeIds);

    const lastSearch = {
      searchQueryUpdated,
      titleSearch,
      area,
      numberOfFilters,
    };

    return lastSearch;
  };

  const getFormattedSection = (section: SECTIONS) => {
    const sectionDisplayName = formattedSection[section as SECTIONS] ?? section;
    return sectionDisplayName;
  };
  return {
    createLastSearch,
    generateParams,
    getFormattedSection,
  };
};
